import common from '@/mixin/common.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-news-detail',
  mixins: [common],
  data() {
    return {
      productBgImage: require('@/assets/home/product_bg.png'),
    }
  },
  mounted() {
    // this.scrollToTop();
    this.getnewsDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions('news', ['getnewsDetail']),
    resetBread(){
      // const title = this.zhcn ? this.newsInfo.title_cn : this.newsInfo.title_en;
	  const title = this.newsInfo.title_en
      if (!title) return;
      if (this.$refs.breadcrumb) {
        this.$refs.breadcrumb.changeLastBread(title);
      }
    },
    changePageLanguage() {
      this.resetBread();
    },
    showDetail(params = null) {
      console.log(' === ', params)
      if (!params || !params.code) {
        return;
      }
      this.$router.push(`/news/detail/${params.code}`);
    },
    showSearch(params = null) {
      if (!params) return;
      this.$router.push(`/search/${encodeURIComponent(params)}`);
    }
  },
  computed: {
    ...mapGetters('news', ['newsInfo']),
  },
  watch: {
    newsInfo: {
      handler: function() {
        this.resetBread();
      },
      deep: true,
    }
  }
}
